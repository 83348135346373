@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&family=Source+Serif+4:ital,opsz,wght@0,8..60,200..900;1,8..60,200..900&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
}


h1, h2, h3, h4, h5, h6,
p, ul, ol, li, blockquote,
pre, hr, address, fieldset, legend,
table, caption, th, td,
form, input, button, textarea, select, label {
  margin: 0;
  padding: 0;
}

img, a {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  text-decoration: none;
}

.sectionlayout2{
  max-width: 1640px;
  margin: auto;
}

.sectionlayout{
  max-width: 1640px;
  margin: auto;
  padding: 0 2rem;
}
@media (max-width: 767px) {
  .sectionlayout{
    padding: 0 1rem;
  }
  
}